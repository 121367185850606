import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Heading from '../components/heading'
import SEO from '../components/seo'

export default function Privacy() {
  const heading = 'プライバシーポリシー'

  const contents = '「株式会社 サンリク」（以下、当社）では、お客さまに安心して当社のサービスをご利用いただけるよう、以下のプライバシーポリシーを明確にし、個人情報の保護に細心の注意を払って運営を行っております。'

  const list = [
    {
      id: 'list-01',
      title: '1.個人情報について',
      contents: '当社ではお客様の、氏名、住所、電話番号、E-mailアドレス、年齢、またはそれ以外でお客さま個人を識別できる情報を個人情報として管理しております。お客さまが当社および当社サイトをご利用される際、当サイト上においてご利用に必要な範囲でお客さまに個人情報のご入力をいただいております。'
    },
    {
      id: 'list-02',
      title: '2.登録いただいた個人情報について',
      contents: '当社および当社サイトでは、ご登録いただいたお客さまの個人情報を、原則として以下に掲げる目的に限り利用させていただきます。<br />' +
        '(1) ご登録いただいた商品・サービスのお申し込みの確認やお届けのため<br />' +
        '(2) お客様に対して適切なサービスを提供するため<br />' +
        '(3) 当社よりお客様に対して商品およびサービスのご案内をお送りするため 上記の目的で個人情報を利用させていただく場合には、別途その旨をご連絡させていただきます。<br />' +
        '<br />' +
        'なお当社および当社サイトでは、お客様より収集させていただいた個人情報を適切に管理し、下記の(1) から (4) に掲げる場合を除き、お客様の承諾を得た会社以外の第三者に提供、開示等は行いません。 これ以外で個人情報を開示させていただく場合には、別にその旨をご連絡します。<br />' +
        '(1) ご購入商品のお届けために、契約関係にある配送業者または業務提携先に送付のための情報を開示させていただく場合<br />' +
        '(2) ご利用のサービスについて、ご登録いただいたお客さまの銀行口座、クレジットカード番号等の正当性、もしくはご利用料金のご入金状況を確認するために、関係する金融機関に照会させていただく場合<br />' +
        '(3) お客様のお買い物の決済に当たり、その手続きのために契約関係にあるパートナー企業が利用する場合<br />' +
        '(4) 裁判所や警察等の公的機関から法律や条例に基づく照会を受けて開示が請求され、これに応じる場合'
    },
    {
      id: 'list-03',
      title: '3.ご登録いただいた個人情報の管理について',
      contents: '当社および当社サイトでは、ご登録いただいた個人情報を適切に管理・保護いたします。また、紛失、破壊、不正な流出、改ざん、不正アクセスの危険から保護するために安全対策を講ずるとともに、万一問題が発生した時には速やかに対処いたします。 また、お客様が、お客様の個人情報の確認、訂正等を希望される場合には、お客様に対する当社までご連絡いただければ、合理的な範囲ですみやかに対応させていただきます。'
    },
    {
      id: 'list-04',
      title: '4.SSLの利用について',
      contents: '当社サイト内には、お客様の個人情報を保護するために「SSL」に対応しているコンテンツ が一部あります。Netscape Navigator、Microsoft Internet Explorerといったセキュリティ機能に対応したブラウザを使用することで、 お客様が入力される名前や住所あるいは電話番号などの個人情報が自動的に暗号化されて送受信されるため、 万が一、送信データが第三者に傍受された場合でも、内容が盗み取られる心配はありません。SSLに対応していないブラウザをご利用の場合は、弊社のウェブサイトにアクセスできなかったり、 情報の入力ができない場合があります。'
    },
    {
      id: 'list-05',
      title: '5.Cookie（ クッキー）の利用について',
      contents: '当社サイトには、「クッキー（Cookie）」と呼ばれる技術を利用したページがあります。 クッキーとは、Webサーバからお客様のブラウザに送信される小規模なデータのことをいい、 お客様のディスクにファイルとして格納されるものもあります。多くのサイトで、利用者に有益な機能を提供する目的で クッキーが使用されています。 クッキーにより、利用者の使用するコンピュータは特定されますが、利用者個人の身元を特定できるわけでは ありません。'
    },
    {
      id: 'list-06',
      title: '6.個人情報保護に関する法令等の遵守について',
      contents: '当社および当社サイトは、個人情報に関係する法令その他各種の規範を遵守いたします。'
    },
    {
      id: 'list-07',
      title: '7.プライバシーポリシーの変更について',
      contents: 'この原則は、サービス内容の変更等に基づいて、随時変更することがあります。なおその場合には、当サイト上に常に最新のプライバシーポリシーを掲載いたします。'
    },
    {
      id: 'list-08',
      title: '8.個人情報に関するお問合せについて',
      contents: '当サイトではご利用者からのお問い合わせにお応えするために、必要な窓口を設けております。<br />' +
        '株式会社 サンリク　 情報管理担当<br />' +
        '意見・ご質問はこちらまでお寄せください。 →　<a href="/contact" class="underline">お問い合わせ</a>'
    },
  ]

  return (
    <Layout>
      <SEO title="個人情報保護方針 | 株式会社サンリク" />
      <PageTitle titleJP="個人情報保護方針" titleEN="PRIVACY POLICY" />
      <div className="max-w-screen-lg mx-auto mt-20 px-6 md:px-0">
        <Heading heading={heading} />
        <p
          dangerouslySetInnerHTML={{ __html: contents }}
          className={`mt-6 leading-7`}
        />
      </div>

      <div className={`max-w-screen-lg mx-auto mt-20 px-6 md:px-0`}>
        {list.map(item => (
          <div key={item.id} className={`mt-8`}>
            <h4 className={`font-medium`}>{item.title}</h4>
            <div
              dangerouslySetInnerHTML={{__html: item.contents}}
              className={`mt-4 leading-7`}
            />
          </div>
        ))}
      </div>
    </Layout>
  )
}
